<template
  src="@/../../../templates/brand/components/templates/shop/product/PricingTiers.html"
>
</template>

<script>
import _ from 'lodash/fp'
import { mapState, mapGetters } from 'vuex'
import accounting from 'accounting'

const sizeOrder = [
  'jm',
  'jl',
  'jx',
  'p',
  'xs',
  's',
  'm',
  'sm',
  'l',
  'x',
  'xl',
  'lxl',
  'xxl',
  '2',
  '2x',
  '2xl',
  'xxxl',
  '3',
  '3x',
  '3xl',
  'xxxxl',
  '4',
  '4x',
  '4xl',
  'xxxxxl',
  '5',
  '5x',
  '5xl'
]

export default {
  name: 'Pricing-tiers',
  created() {
    this.selectedQtys = this.setInitialQtys()
    this.$watch('product', () => {
      this.selectedQtys = this.setInitialQtys()
    })
  },
  mounted() {
    let _el = document.querySelectorAll('.progress-segment.two')[0]
    if (!_el) return
    let _firsttier = this.activeVolume / this.tiers[0].volume
    this.activeMark = this.product.adjustments.length
      ? _el.clientWidth * _firsttier * 2
      : _el.clientWidth * 0.19
  },
  props: ['product', 'summary'],
  data() {
    return {
      selectedQtys: {},
      ordinals: [
        'first',
        'second',
        'third',
        'fourth',
        'fifth',
        'sixth',
        'seventh'
      ],
      activeMark: 0,
      showSkus: false // so we don't get ugly errors from template before props arrive...
    }
  },
  computed: {
    ...mapState({
      user: ({ App }) => App.user,
      // product: ({ Product }) => Product.atomicProduct,
      program: ({ Collection }) => Collection.program
    }),
    ...mapGetters({
      appconfig: 'const'
    }),
    cart() {
      return this.program.settings.communityVolume
        ? this.user.profile.preorders
        : this.user.profile.shoppingcarts[this.program.settings.cart]
    },
    tiers() {
      if (!this.product) return []
      if (!this.product.adjustments.length)
        return [{ volume: 0, value: this.product.price }]
      return _.sortBy(a => a.volume, this.product.adjustments)
    },
    tierKey() {
      return (
        this.product.familyKey +
        ((this.product.variationField && this.product.variationField.key) ||
          this.product.variationKey ||
          '')
      )
    },
    activeVolume() {
      if (!this.program || this.program.noActiveQty) return 0
      if (_.get('minActiveQty', this.product.extradata)) {
        return this.product.extradata.minActiveQty
      }
      if (this.program.minActiveQty) return this.program.minActiveQty
      // if (this.product.minQty) return this.product.minQty
      return 0
    },
    currentVolume() {
      if (this.program && this.program.settings.communityVolume) {
        return this.product.items
          ? _.reduce(
              (
                sum // do we really not need the value?
              ) =>
                sum + this.program.summary[this.tierKey]
                  ? this.program.summary[this.tierKey].volume
                  : 0,
              0,
              this.product.items
            )
          : this.program.summary[this.product.SKU]
          ? this.program.summary[this.product.SKU].volume
          : this.program.summary[this.tierKey]
          ? this.program.summary[this.tierKey].volume
          : 0
      } else {
        return this.summary.family[
          this.product.familyKey + this.product.variationField.key
        ]
      }
    },
    volumePercent() {
      if (!this.currentVolume || !this.tiers.length) return 0
      return (this.currentVolume / _.last(this.tiers).volume) * 100
    },
    currentValue() {
      if (
        !this.program ||
        _.isEmpty(this.product) ||
        _.isEmpty(this.rangedTiers)
      )
        return 0
      // return _.find((t) => t.volume > this.currentVolume, this.tiers).value;
      // let _tier = this.rangedTiers[0];
      if (this.program.summary[this.tierKey]) {
        return this.rangedTiers.find(i =>
          i.range(this.program.summary[this.tierKey].volume)
        ).value
      } else {
        return this.rangedTiers[0].value
      }
    },
    rangedTiers() {
      if (_.isEmpty(this.product)) return []
      // if no adjustments...
      if (this.tiers.length === 1)
        return [
          {
            min: 0,
            max: this.activeVolume * 10,
            range: val => val > 0,
            value: this.product.price
          }
        ]

      let _tiers = []
      for (let i = 0, c = this.tiers.length; i < c; i++) {
        if (i === 0)
          _tiers.push({
            min: 0,
            max: this.tiers[i].volume,
            range: val => val >= 0 && val <= this.tiers[i].volume,
            value: this.product.price
          })
        else
          _tiers.push({
            min: this.tiers[i - 1].volume,
            max: this.tiers[i].volume,
            range: val =>
              val >= this.tiers[i - 1].volume && val <= this.tiers[i].volume,
            value: this.tiers[i - 1].value
          })
      }
      _tiers.push({
        min: _.last(this.tiers).volume,
        max: _.last(this.tiers).volume + 1,
        range: val => val > _.last(this.tiers).volume,
        value: _.last(this.tiers).value
      })
      return _tiers
    },
    isCustomized() {
      if (!this.product) return false
      if (!this.product.items) {
        return (
          this.cart.details[this.product.SKU] &&
          this.cart.details[this.product.SKU].customize
        )
      } else {
        let _cartSKUs = _.keys(this.cart.items)
        let _itemSKUs = _.map(i => i.SKU, this.product.items)
        if (!_cartSKUs.length) return false
        let _common = _.intersection(_cartSKUs, _itemSKUs)
        if (!_common.length) return false
        return this.cart.details[_common[0]].customize
      }
    },
    softQty() {
      if (this.product.items) {
        return _.reduce(
          (sum, p) => {
            const val =
              typeof this.selectedQtys[p].qty !== 'number'
                ? 0
                : this.selectedQtys[p].qty
            sum += val
            return sum
          },
          0,
          _.keys(this.selectedQtys)
        )
      } else {
        if (this.selectedQtys[this.product.SKU]) {
          const val =
            typeof this.selectedQtys[this.product.SKU].qty !== 'number'
              ? 0
              : this.selectedQtys[this.product.SKU].qty
          return val
        } else return 0
      }
    },
    sortedProduct() {
      const mbrs = {}
      let _out = []
      if (!this.product.items || !this.product.items.length)
        return [this.product]
      if (
        (this.product.relatedBy.toLowerCase() === 'size' ||
          this.product.unitField.name.toLowerCase() === 'size') &&
        this.product.items &&
        this.product.items.some(u => !u.unitField.order)
      ) {
        this.product.items.forEach(
          item =>
            (mbrs[
              (item.unitField && String(item.unitField.key).toLowerCase()) ||
                item.size.toLowerCase()
            ] = item._id)
        )
        const _k = _.keys(mbrs)
        const _inter = _.intersection(_k, sizeOrder)
        if (_inter.length === _k.length) {
          _out = _.compact(
            sizeOrder.map(item =>
              this.product.items.find(itm => itm._id === mbrs[item])
            )
          )
        } else if (_inter.length < _k.length) {
          const _diff = _.difference(_k, _inter)
          _out = _.compact(
            sizeOrder.map(item =>
              this.product.items.find(itm => itm._id === mbrs[item])
            )
          )
          _diff.forEach(i => {
            _out.push(this.product.items.find(itm => itm._id === mbrs[i]))
          })
        } else {
          _out = this.product.items
        }
      } else {
        _out = _.sortBy('unitField.order', this.product.items)
      }
      return _out
    }
  },
  // component methods
  methods: {
    progressSegment(a, b, incr) {
      if (incr <= a) {
        return 0
      }
      if (incr >= b) {
        return 100
      }
      if (incr >= a && incr <= b) {
        return ((incr - a) / b) * 100
      }
    },
    aggregateProgressSegment(a, b, incr) {
      let _testval = this.currentVolume
      if (this.cart.items[this.product.SKU]) {
        if (this.cart.items[this.product.SKU] !== incr) {
          _testval -= this.cart.items[this.product.SKU]
          _testval += incr
        }
      } else if (incr) {
        _testval += incr
      }
      if (_testval <= a) {
        return 0
      }
      if (_testval >= b) {
        return 100
      }
      if (_testval >= a && this.currentVolume <= b) {
        return ((_testval - a) / b) * 100
      }
    },
    setInitialQtys() {
      let _initialvals = {}
      if (!this.product.items || !this.product.items.length) {
        _initialvals[this.product.SKU] = { qty: 0 }
        if (this.cart.items[this.product.SKU])
          _initialvals[this.product.SKU].qty = this.cart.items[this.product.SKU]
      } else {
        _.each(i => {
          _initialvals[i.SKU] = { qty: 0 }
          if (this.cart.items[i.SKU]) {
            _initialvals[i.SKU].qty = this.cart.items[i.SKU]
          }
        }, this.product.items)
      }

      this.showSkus = true
      return _initialvals
    },
    updateItemQtys(evt) {
      let _qty = evt.target.value ? parseInt(evt.target.value, 10) : 0
      if (typeof this.selectedQtys[evt.target.dataset.sku].qty !== 'number') {
        this.selectedQtys[evt.target.dataset.sku].qty = 0
      }
      let _min = this.program.noMinQty
        ? 0
        : this.product.minQty || this.program.minQty
      if (
        _qty &&
        _qty + _.sum(_.values(this.selectedQtys).map(i => i.qty)) < _min
      )
        return
      // if (this.selectedQtys[evt.target.dataset.sku].qty !== _qty) {
      if (!this.product.items)
        this.selectedQtys[evt.target.dataset.sku].qty = _qty
      this.$emit('qtyChange', {
        _id: evt.target.dataset.id,
        sku: evt.target.dataset.sku,
        qty: _qty
      })
      // }
    },
    progressSegmentSize(t) {
      return (t.volume / _.last(this.tiers).volume) * 100
    },
    // priceQty (item) {
    //   return this.currentValue;
    // },
    customize(evt) {
      this.$emit('customize', evt.target.checked)
    },
    inventoryControl(inv) {
      if (inv > 0) {
        return inv
      } else {
        return '<i class="fa fa-exclamation-triangle" style="color:#f60;" title="Item is on backorder" aria-hidden="true"></i>'
      }
    },
    currencyLocale(val) {
      return accounting.formatMoney(
        val,
        this.appconfig.locale[this.$i18n.locale].currency
      )
    }
    // priceQty: (val, item) => {
    //   if (!item || !item.price) return; // why does this get called with no value?
    //   // return item price from adjustments for quantity range of order
    //   const tier = _.find(item.adjustments, (itm) => itm.volume > this.selectedQtys[item.SKU].qty);
    //   if (tier) return tier.value;
    //   else return val;
    // }
  },
  events: {
    'updated.cart'() {
      // this.cart = this.user.profile.cart;
      const _initialvals = {}
      if (!this.product.items || !this.product.items.length) {
        _initialvals[this.product.SKU] = {
          qty: this.cart.items[this.product.SKU]
        }
      } else {
        _.each(i => {
          _initialvals[i.SKU] = { qty: 0 }
          if (this.cart.items[i.SKU]) {
            _initialvals[i.SKU].qty = this.cart.items[i.SKU]
          }
        }, this.product.items)
      }

      this.selectedQtys = _.assign({}, _initialvals)
    },
    'product.changed'() {
      this.showSkus = false
      this.selectedQtys = this.setInitialQtys()
    }
  }
}
</script>

<style
  src="@/../../../templates/brand/components/styles/shop/product/PricingTiers.css"
></style>
